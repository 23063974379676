import { Link } from 'react-router-dom';
import logo from "./logo.png"
import ApedaLogo from "./APEDA-Logo.png"
import indiaOrganic from "./indiaOrganiclogo.png"
import './App.css';

const logo1 = {
  maxWidth: "58%",
  height: "60%"
}

const logo2 = {
  maxWidth: "110%",
  height: "50%"
}

const logo3 = {
  maxWidth: "35%",
  height: "50%"
}

function Home() {
  return (
    <div className="container-fluid" style={{minHeight:"100vh"}}>
      <div className="row align-items-center  rounded justify-content-between  p-3" style={{border:"3px solid green"}}>
        <div className="col-lg-4 mt-2 d-flex justify-content-center justify-content-lg-start" >
          <img src={logo} style={logo1} alt="" />
        </div>
        
        <div className="col-lg-4 mt-4 d-flex justify-content-center" >
          <img src={ApedaLogo} style={logo2} alt="" />
        </div>

        <div className="col-lg-4 mt-3 d-flex  justify-content-lg-end justify-content-center" >
          <img src={indiaOrganic} style={logo3} alt="" />
        </div>
        
      </div>
      <div className="row justify-content-center p-5  mb-2 mt-5">
        <div className="col-md-6 mt-2 d-flex justify-content-center mb-2">
          <Link to="/Visitors" type="button" class="btn exhibitor btn-success btn-lg btn-block">Visitor</Link>
        </div>
        <div className="col-md-6 d-flex justify-content-center mt-2 mb-2">
          <Link to="/Exhibitors" className="btn exhibitor btn-success btn-lg btn-block">Exhibitors In APEDA</Link>
        </div>
      </div>
      <div>
       
      </div>
    </div>
  );
}

export default Home;
