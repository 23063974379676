import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ApedaLogo from "./APEDA-Logo.png"
import logo from "./logo.png"
import css from "./App.css"
import axios from 'axios';

const Visitors = () => {
    const [inputVisitors, setInputVisitors] = useState({
        Name: "",
        Phone: "",
        Email: "",
        CompanyName: "",
        Availability: "",
        BusinessArea: ""
    });
    const [data1, setData1] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        // fetch('https://sheetdb.io/api/v1/jayxc48ozmfil')
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data)
        //         setData1(data);
        //     });

        //primary
        axios.get("https://sheet.best/api/sheets/58ebe75e-0721-4f47-b5b4-c9096c7a3fb1").then((res) => {
            setData1(res.data);
            debugger
        })
        //primary
    }
    // css

    const logostyle1 = {
        height: "75%",
        width: "73%",
    }

    const logostyleapeda = {
        height: "100%",
        width: "130%",

    }

    const inputBox = {

        outline: "0",
        borderWidth: "0 0 2px",
        borderColor: "green",
    }

    // css end

    const inputVisitorsHandler = (e) => {

        const { name, value } = e.target;
        setInputVisitors((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const emailValidation1 = (evt) => {
        if (evt.includes('@') && (evt.includes('.com') || evt.includes('.in') || evt.includes('.edu'))) {
            return 1;
        }
        else {
            return 0;
        }
    }

    const save = (evt) => {
        evt.preventDefault();
        setLoading(true);
        var emailChecker = emailValidation1(inputVisitors.Email);
        if (emailChecker === 0) {
            setLoading(false);
            
            alert("please check you email ");
            return;
        }
        debugger
        var emailValidation = data1.filter((x) => x.Email === inputVisitors.Email);
        if (emailValidation.length > 0) {
            setLoading(false);
            alert("Email Already Registered")

            return;
        }
        var data = {
            ...inputVisitors,
        }
        console.log(data);
        debugger

        // fetch('https://sheetdb.io/api/v1/jayxc48ozmfil', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data),

        // })
        //     .then((response) => response.json())
        //     .then((data) => {

        //         if (data.created === 1) {
        //             setLoading(false);
        //             alert("Data Submitted successfully");
        //             window.location.href = "/";

        //         }
        //         else {
        //             alert("Error Occured Try Again Later")
        //         }
        //     });

        axios.post("https://sheet.best/api/sheets/58ebe75e-0721-4f47-b5b4-c9096c7a3fb1", data).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                alert("Thanks For Feedback...!!");
                window.location.href = "/";
            }
            else {
                alert("Error Occured Try Again Later");
            }
        })

    }

    return (
        <div className="container-fluid p-3">

            <div className="row justify-content-center p-3">
                <div className="col-md-10 justify-content-center p-1 d-flex" style={{ border: "5px solid black" }}>

                    <div className="col-md-12" style={{ border: "3px solid black" }}>
                        <div className="col-md-12  d-flex flex-wrap justify-content-between mb-4">

                            <div className="col-md-4 mt-3 d-flex justify-content-center justify-content-md-start ">
                                <img src={logo} style={logostyle1} alt="" />
                            </div>
                            <div className="col-md-5  mt-3 mb-2 d-flex justify-content-center">
                                <img className='logoApeda' src={ApedaLogo} style={logostyleapeda} alt="" />
                            </div>

                            <div className="col-md-3  d-flex align-items-center justify-content-md-end justify-content-center">
                                <Link to="/" className="btn btn-info">Back</Link>
                            </div>

                        </div>
                        <div className="col-sm-12 d-flex justify-content-center">
                            <div className="col-md-4 text-center flex-column">
                                <h5>MACFRUT 2024</h5>
                                <h5>8<sup>th</sup> to 10<sup>th</sup> May 2024</h5>
                                <h5>Rimini, Italy</h5>
                            </div>
                        </div>
                        <div className="col-sm-12 bg-success rounded text-light p-1 mt-3 text-center">
                            <h4>Feedback Form Visitors</h4>
                        </div>
                        <div className="col-sm-12 mt-3   ">
                            <form onSubmit={(e) => save(e)} className='form p-2'>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Name</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='Name' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Company </b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='CompanyName' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required'><b>Email</b></div>
                                        <div className='col-sm-10'>
                                            <input type="email" name='Email' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Contact Number</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='Phone' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Area Of Business</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='BusinessArea' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11  '><b>Did you get to see the product you are looking for?</b></div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="Availability" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>No&nbsp;</div>
                                            <input type="radio" name="Availability" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                {loading === false ? <h6 className="font-weight-bold text-primary">{ }</h6> :
                                    <div className=" text-primary">
                                        <strong>Saving...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                }
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-11 mt-3 d-flex justify-content-end'>
                                            <button type='submit' className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>

    )
}

export default Visitors