import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ApedaLogo from "./APEDA-Logo.png"
import logo from "./logo.png"
import css from "./App.css"
import axios from 'axios';


const Exhibitors = () => {
    const [inputExhibitors, setExhibitors] = useState({
        ExhibitorCompanyName: "",
        ContactName: "",
        Address: "",
        ContactEmail: "",
        ContactPhone: "",
        Website: "",
        Enquiries: "",
        BusinessWorth: "",
        ProductDisplayed: "",
        ProductResponse: "",
        OverallExperience: "",
        OverallManagement: "",
        DesignRating: "",
        Participation: "",
    });
    const [data1, setData1] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {

        //secondary
        // fetch('https://sheetdb.io/api/v1/tbea2a4oxfui6')
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data)
        //         setData1(data);
        //     });
        //secondary
        //primary
        axios.get("https://sheet.best/api/sheets/461b62df-af7a-45ae-91a6-9dd4c43c5687").then((res)=>{
            setData1(res.data);
            debugger
        })
        //primary
    }
    // css

    const logostyle1 = {
        height: "75%",
        width: "73%",
    }

    const logoApeda = {
        height: "100%",
        width: "130%",
    }

    const inputBox = {

        outline: "0",
        borderWidth: "0 0 2px",
        borderColor: "green",
    }

    // css end

    const inputVisitorsHandler = (e) => {
        const { name, value } = e.target;
        setExhibitors((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const emailValidation1 = (evt) => {
        if (evt.includes('@') && (evt.includes('.com') || evt.includes('.in') || evt.includes('.edu'))) {
            return 1;
        }
        else {
            return 0;
        }
    }
    const save = (evt) => {
        evt.preventDefault();
        setLoading(true)
        var emailChecker = emailValidation1(inputExhibitors.ContactEmail);
        if (emailChecker === 0) {
            setLoading(false);

            alert("please check you email ");
            return;
        }
        debugger
        var emailValidation = data1.filter((x) => x.ContactEmail === inputExhibitors.ContactEmail);
        if (emailValidation.length > 0) {
            setLoading(false);
            alert("Email Already Registered")

            return;
        }


        var exhibitorsData = {
            ...inputExhibitors,
        }
        console.log(exhibitorsData);
        // fetch('https://sheetdb.io/api/v1/tbea2a4oxfui6', {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(exhibitorsData)
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data)
        //         if (data.created === 1) {
        //             setLoading(false);
        //             alert("Data Submitted successfully");
        //             window.location.href = "/";
        //         }
        //         else {
        //             alert("Error Occured Try Again Later")
        //         }
        //     });

        axios.post("https://sheet.best/api/sheets/461b62df-af7a-45ae-91a6-9dd4c43c5687", exhibitorsData).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                alert("Thanks For Feedback...!");
                window.location.href = "/";
            }
            else {
                alert("Error Occured Try Again Later");
            }
        })


    }

    return (
        <div className="container-fluid p-3">

            <div className="row justify-content-center p-3">
                <div className="col-sm-10 justify-content-center p-1 d-flex" style={{ border: "5px solid black" }}>

                    <div className="col-md-12" style={{ border: "3px solid black" }}>
                        <div className="col-md-12  d-flex flex-wrap justify-content-between mb-4">

                            <div className="col-md-4 mt-3 d-flex justify-content-center justify-content-md-start ">
                                <img src={logo} style={logostyle1} alt="" />
                            </div>
                            <div className="col-md-4  mt-3 mb-2 d-flex justify-content-center">
                                <img src={ApedaLogo} className='logoApeda' style={logoApeda} alt="" />
                            </div>

                            <div className="col-md-4  d-flex align-items-center justify-content-md-end justify-content-center">
                                <Link to="/" className="btn btn-info">Back</Link>
                            </div>

                        </div>
                        <div className="col-sm-12 d-flex justify-content-center">
                            <div className="col-md-4 text-center flex-column">
                                <h5>MACFRUT 2024</h5>
                                <h5>8<sup>th</sup> to 10<sup>th</sup> May 2024</h5>
                                <h5>Rimini, Italy</h5>
                            </div>
                        </div>
                        <div className="col-sm-12  mt-3 p-1 text-center bg-success text-light rounded">
                            <h4>Feedback Form Exhibitor</h4>
                        </div>
                        <div className="col-sm-12 mt-3 ">
                            <form onSubmit={(e) => save(e)} className='form p-2'>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Name Of Company </b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='ExhibitorCompanyName' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Contact Person's Name</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='ContactName' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Address</b></div>
                                        <div className='col-sm-10'>
                                            <textarea cols={20} rows={1} type="text" name='Address' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 required'><b>Email</b></div>
                                        <div className='col-sm-10'>
                                            <input type="email" name='ContactEmail' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Contact Number</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='ContactPhone' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Website</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='Website' className='form-control mt-2 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>No. Business Enquiries</b></div>
                                        <div className='col-sm-10'>
                                            <input type="number" name='Enquiries' className='form-control mt-3 mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Business Worth</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='BusinessWorth' className='form-control mb-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-3 col-sm-5 '><b>Product Displayed</b></div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="ProductDisplayed" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-sm-2 d-flex'>
                                            <div>No&nbsp;</div>
                                            <input type="radio" name="ProductDisplayed" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How was the response of your products in this event? </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="ProductResponse" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="ProductResponse" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="ProductResponse" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="ProductResponse" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="ProductResponse" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How was your overall experience at MACFRUT 2024?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="OverallExperience" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="OverallExperience" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="OverallExperience" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="OverallExperience" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="OverallExperience" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11  '><b>How did the design and layout of the APEDA India Pavilion?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="DesignRating" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="DesignRating" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="DesignRating" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="DesignRating" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="DesignRating" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11 '><b>How did you find the overall management of the Pavilion?  </b></div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>1</div>
                                            <input type="radio" name="OverallManagement" value={1} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>2</div>
                                            <input type="radio" name="OverallManagement" value={2} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>3</div>
                                            <input type="radio" name="OverallManagement" value={3} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>4</div>
                                            <input type="radio" name="OverallManagement" value={4} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-1 d-flex'>
                                            <div>5</div>
                                            <input type="radio" name="OverallManagement" value={5} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-sm-2 mt-3 '><b>Suggetions Comment</b></div>
                                        <div className='col-sm-10'>
                                            <input type="text" name='BusinessWorth' className='form-control mb-1 mt-4 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 ">
                                    <div className="form-group row">
                                        <div className='col-lg-7 col-sm-11'><b>Would you like to participate at MACFRUT 2025:</b></div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>Yes</div>
                                            <input type="radio" name="Participation" value={true} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                        <div className='col-lg-2 d-flex'>
                                            <div>No&nbsp;</div>
                                            <input type="radio" name="Participation" value={false} className='form-control mb-1 mt-1 form-control-user ' onChange={inputVisitorsHandler} style={inputBox} />
                                        </div>
                                    </div>
                                </div>
                                {loading === false ? <h6 className="font-weight-bold text-primary">{ }</h6> :
                                    <div className=" text-primary ml-3">
                                        <strong>Saving...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                }
                                <div className="col-sm-12 ">
                                    <div className="form-group row  ">
                                        <div className='col-sm-11  d-flex justify-content-end mt-3'>
                                            <button type='submit' className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>

    )
}

export default Exhibitors