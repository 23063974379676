import { Link } from 'react-router-dom';
import logo from "./logo.png"
import ApedaLogo from "./APEDA-Logo.png"
import indiaOrganic from "./indiaOrganiclogo.png"
import './App.css';

const logo1 = {
    maxWidth: "58%",
    height: "60%"
}

const logo2 = {
    maxWidth: "110%",
    height: "50%"
}

const logo3 = {
    maxWidth: "35%",
    height: "50%"
}

const AdminPanel = () => {
    return (
        <div className="container-fluid" style={{ minHeight: "100vh" }}>
            <div className="row align-items-center bg-success border border-info justify-content-between  p-3">
                <div className="col-lg-4 mt-2 d-flex justify-content-center justify-content-lg-start" >
                    <img src={logo} style={logo1} alt="" />
                </div>

                <div className="col-lg-4 mt-4 d-flex justify-content-center" >
                    <img src={ApedaLogo} style={logo2} alt="" />
                </div>

                <div className="col-lg-4 mt-3 d-flex  justify-content-lg-end justify-content-center" >
                    <img src={indiaOrganic} style={logo3} alt="" />
                </div>

            </div>
            <div className="col-sm-12 rounded border border-info mt-3 p-1 text-primary text-center">
                <h2><b>Admin Panel</b></h2>
            </div>
            <div className="row justify-content-center p-5  mb-2 mt-5">
                <div className="col-md-6 mt-2">
                    <Link to="https://docs.google.com/spreadsheets/d/1uqMC9Q1IR62-MEbW4rvJbydtktgWeoqy00w-F00vj84/edit?pli=1#gid=0" target='_blank' type="button" class="btn btn-success btn-lg btn-block">Visitors Entries</Link>
                </div>
                <div className="col-md-6 mt-2 mb-2">
                    <Link to="https://docs.google.com/spreadsheets/d/13i3J3f3ofxTAzCXc1C8q0qaFaPDTVxw1mPPwGG_PoYY/edit#gid=0" target='_blank' className="btn btn-success btn-lg btn-block">Exhibitors Entries In APEDA Pavilion</Link>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}

export default AdminPanel