import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Visitors from "./Visitors";
import Exhibitors from "./Exhibitors";
import Home from "./Home";
import AdminPanel from "./AdminPanel";
import VisitorsList from "./VisitorsList";
import ExhibitorsList from "./ExhibitorsList";

function Router() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        exact
                        path="/Visitors"
                        element={<Visitors />}
                    />
                    <Route
                        exact
                        path="/Exhibitors"
                        element={<Exhibitors />}
                    />
                    <Route
                        exact
                        path="/VisitorsList"
                        element={<VisitorsList />}
                    />

                    <Route
                        exact
                        path="/AdminPanel"
                        element={<AdminPanel />}
                    />
                    <Route
                        exact
                        path="/ExhibitorsList"
                        element={<ExhibitorsList />}
                    />

                </Routes>
            </BrowserRouter >
        </>
    );
}

export default Router;