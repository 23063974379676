import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ApedaLogo from "./APEDA-Logo.png"
import logo from "./logo.png"
import css from "./App.css"
const ExhibitorsList = () => {
    const [data1, setData1] = useState([]);


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        fetch('https://sheetdb.io/api/v1/tbea2a4oxfui6')
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setData1(data); debugger
            });
    }
    // css

    const logostyle1 = {
        height: "75%",
        width: "73%",
    }

    const logoApeda = {
        height: "100%",
        width: "130%",
    }



    // css end






    return (
        <div className="container-fluid p-3">

            <div className="row d-flex justify-content-center  p-1 flex-column" style={{ border: "5px solid black" }}>
                <div className="col-sm-12" style={{ border: "3px solid black" }}>


                    <div className="col-sm-12 justify-content-center d-flex">

                        <div className="col-md-12">
                            <div className="col-md-12  d-flex flex-wrap justify-content-between mb-4">

                                <div className="col-md-4 mt-3 d-flex justify-content-center justify-content-md-start ">
                                    <img src={logo} style={logostyle1} alt="" />
                                </div>
                                <div className="col-md-4  mt-3 mb-2 d-flex justify-content-center">
                                    <img src={ApedaLogo} className='logoApeda' style={logoApeda} alt="" />
                                </div>

                                <div className="col-md-4  d-flex align-items-center justify-content-md-end justify-content-center">
                                    <Link to="/AdminPanel" className="btn btn-info">Back</Link>
                                </div>

                            </div>

                        </div>

                    </div>



                    <div className="card shadow mb-4">
                        <div className="card-header d-flex py-3">
                            <h6 className="m-0 mt-1 font-weight-bold text-primary ">Exhibitors List</h6>
                        </div>

                        <form style={{ overflowX: "auto", overflowY: "auto", position: "sticky", zIndex: 1, maxHeight: "500px" }}>

                            <div className='col-sm-12'>
                                <table class="table table-xl">
                                    {/* <caption>List of users</caption> */}
                                    <thead className='thead bg-success' style={{ position: "sticky", top: "-19px", zIndex: "1" }}>
                                        <tr>
                                            {/* <th scope="col">Task Id</th> */}
                                            <th scope="col">#</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">Account Manager</th>
                                            <th scope="col">Contact Person</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Website</th>
                                            <th scope="col">Enquiries</th>
                                            <th scope="col">Business Worth</th>
                                            <th scope="col">Product Displayed</th>
                                            <th scope="col">Product Response</th>
                                            <th scope="col">MACFRUT Experience</th>
                                            <th scope="col"> Pavilion Rating</th>
                                            <th scope="col">Management Rating</th>
                                            <th scope="col">Suggestions</th>
                                            <th scope="col">MACFRUT 2025 Participation</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr></tr>


                                    </tbody>
                                </table>

                            </div>

                        </form>
                    </div>


                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default ExhibitorsList